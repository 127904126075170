<template>
  <h1>{{ tr('Authenticating…') }}</h1>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { inject, onMounted } from 'vue'
import { useStore } from 'vuex'

import auth from './auth.js'

export default {
  setup() {
    const store = useStore()

    const tr = inject('tr')

    const { handleAuthFlow } = auth.setup()

    onMounted(async () => {
      if (store.getters.jwtIsValid(new Date())) {
        return
      }

      handleAuthFlow()
    })

    return {
      tr,
    }
  },
}
</script>
