// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fetchWithTimeout, onSignOut } from '@/common/shared'
import { validateJwt } from './jwt'
import getEnv from '@/utils/env'
import { setEhrPatientSession } from './fhir'

export function jsonToURLEncoded(element, key, list) {
  list = list || []
  if (typeof (element) === 'object') {
    for (var idx in element)
      jsonToURLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list)
  } else {
    list.push(key + '=' + encodeURIComponent(element))
  }
  return list.join('&')
}

export default {
  setup() {
    const route = useRoute()
    const store = useStore()

    // TODO: Find secure way to make secrets available to Vue3 app!
    const keycloakState = getEnv('VUE_APP_KEYCLOAK_STATE')
    if (!keycloakState) { alert('missing VUE_APP_KEYCLOAK_STATE env var!'); return }
    const keycloakBaseUrl = getEnv('VUE_APP_KEYCLOAK_BASE_URL')
    if (!keycloakBaseUrl) { alert('missing VUE_APP_KEYCLOAK_BASE_URL env var!'); return }
    const keycloakClientId = getEnv('VUE_APP_KEYCLOAK_CLIENT_ID')
    if (!keycloakClientId) { alert('missing VUE_APP_KEYCLOAK_CLIENT_ID env var!'); return }
    const keycloakClientSecret = getEnv('VUE_APP_KEYCLOAK_CLIENT_SECRET')
    if (!keycloakClientSecret) { alert('missing VUE_APP_KEYCLOAK_CLIENT_SECRET env var!'); return }

    const processAuthResponse = (authResponse, redirectUrl) => {
      store.dispatch('onLogin', { authResponse, redirectUrl })
    }

    const checkInactivity = () => {
      const logoutAfterInactivityMinutes = (store.getters.featureToggles && store.getters.featureToggles.logoutAfterInactivityMinutes) || 0
      if (logoutAfterInactivityMinutes <= 0) { return }
      const nowInSecondsSinceEpoch = Math.round((new Date().getTime()) / 1000)
      const inactivityInMinutes = Math.round((nowInSecondsSinceEpoch - store.getters.lastActivitySecondsSinceEpoch) / 60)
      console.log(`inactivity in minutes: ${inactivityInMinutes}, logoutAfterInactivityMinutes=${logoutAfterInactivityMinutes}`)
      if (inactivityInMinutes >= logoutAfterInactivityMinutes) { onSignOut() }
    }

    const callKeycloakToken = async (data, redirectUrl) => {
      const url = `${keycloakBaseUrl}/protocol/openid-connect/token`
      try {
        const fetchParams = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: jsonToURLEncoded(data),
        }
        const response = await fetchWithTimeout(url, fetchParams)
        const result = await response.json()
        if (!result) {
          console.log('ERROR: no result from Keycloak; ignoring login')
          return
        }
        if (!result.access_token) {
          console.log('ERROR: invalid access token, ignoring login:', result)
          return
        }
        const isValid = validateJwt(result.access_token)
        if (!isValid) {
          console.log('ERROR: invalid Keycloak JWT; ignoring login')
          return
        }

        window.access_token = result.access_token
        window.refresh_token = result.refresh_token
        const minRefreshSeconds = result.expires_in <= result.refresh_expires_in ? result.expires_in : result.refresh_expires_in

        const delayMillis = (minRefreshSeconds - 10) * 1000
        console.log(`refreshing access token in ${delayMillis / 1000} seconds...`)
        setTimeout(async () => {
          checkInactivity()
          console.log('refreshing access token')
          const refreshData = {
            grant_type: 'refresh_token',
            client_id: keycloakClientId,
            client_secret: keycloakClientSecret,
            refresh_token: result.refresh_token,
          }
          await callKeycloakToken(refreshData, '')
        }, delayMillis)

        if (data.grant_type === 'authorization_code') {
          processAuthResponse(result, redirectUrl)
        } else {
          store.dispatch('saveAuthResponse', result)
        }
      } catch (e) {
        if (e.name === 'AbortError') {
          console.log('timeout error')
        } else {
          console.log('ERROR:', e)
        }
      }
    }

    const getLaunchIssParam = (redirectUrl) => {
      const params = redirectUrl.split('?')[1]
      return params.replace('iss=', 'aud=')
    }

    const getIdp = (launchIssParam) => {
      if (launchIssParam) {
        const aud = launchIssParam.split('&').find(p =>
          p.startsWith('aud=')).split('=')[1]
        const keycloakEhrLaunchIdp = getEnv('VUE_APP_KEYCLOAK_EHR_LAUNCH_IDP')
        try {
          const kcIdpHint = keycloakEhrLaunchIdp ? JSON.parse(keycloakEhrLaunchIdp)[decodeURIComponent(aud)] : 'epic'
          return kcIdpHint
        } catch (e) {
          console.log('VUE_APP_KEYCLOAK_EHR_LAUNCH_IDP is not a valid JSON string')
        }
      }
      return ''
    }

    const handleAuthFlow = async () => {
      const redirectUrl = route.query.redirect_url  // the originally-requested URL

      const slashPos = location.toString().indexOf('/login')
      const redirectBaseUrlPrefix = location.toString().substring(0, slashPos + '/login'.length)
      let redirectBaseUrl = redirectBaseUrlPrefix + (redirectUrl ? `?redirect_url=${redirectUrl}` : '')
      let launchIssParam = ''
      let kcIdpHint = ''

      // before exchange code is available
      if (redirectUrl && redirectUrl.indexOf('launch') >= 0 && redirectUrl.indexOf('iss=') >= 0) {
        launchIssParam = getLaunchIssParam(redirectUrl)
        kcIdpHint = getIdp(launchIssParam)
        redirectBaseUrl = `${redirectBaseUrlPrefix}?from_fhir=true&idp=${kcIdpHint}`
      }
      // after exchange code is available
      if (route.query.from_fhir === 'true' && route.query.idp) {
        redirectBaseUrl = `${redirectBaseUrlPrefix}?from_fhir=true&idp=${route.query.idp}`
      }

      const queryState = route.query.state
      const querySessionState = route.query.session_state
      const queryCode = route.query.code
      if (queryState && querySessionState && queryCode) {
        if (queryState !== keycloakState) {
          alert('query state does not match keycloak state')
          return
        }

        const data = {
          grant_type: 'authorization_code',
          client_id: keycloakClientId,
          client_secret: keycloakClientSecret,
          code: queryCode,
          redirect_uri: redirectBaseUrl,
        }
        await callKeycloakToken(data, redirectUrl)
        if (route.query.from_fhir === 'true' && route.query.idp) {
          localStorage.setItem('organization', route.query.idp)
          await setEhrPatientSession(store, route.query.idp)
        }
        return
      }

      const url = `${keycloakBaseUrl}/protocol/openid-connect/auth?response_type=code&client_id=${keycloakClientId}&redirect_uri=${encodeURIComponent(redirectBaseUrl)}&state=${keycloakState}&login=true&scope=openid&${launchIssParam}&kc_idp_hint=${kcIdpHint}`

      window.location.href = url
    }

    return {
      handleAuthFlow,
    }
  },
}
