// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { ref } from 'vue'
import { KEYUTIL, KJUR } from 'jsrsasign'
import { fetchWithTimeout } from '@/common/shared.js'
import getEnv from '@/utils/env'

const keycloakBaseUrl = getEnv('VUE_APP_KEYCLOAK_BASE_URL')

const publicKeys = ref([])

const getJson = async (url) => {
  try {
    const response = await fetchWithTimeout(url)
    if (!response.ok) {
      throw new Error(`HTTP error, status=${response.status}`)
    }
    return await response.json()
  } catch (e) {
    if (e.name === 'AbortError') {
      console.log('timeout error')
    } else {
      console.log('ERROR:', e)
    }
  }
  return {}
}

const getJwksUri = async () => {
  const url = `${keycloakBaseUrl}/.well-known/openid-configuration`
  const data = await getJson(url)
  return data.jwks_uri || ''
}

const getPublicKeys = async () => {
  const url = await getJwksUri()
  if (!url) {
    console.log('ERROR: unable to get public keys')
    return
  }
  const data = await getJson(url)
  const keys = data.keys || []
  publicKeys.value = keys
}


export const validateJwt = async (myToken) => {
  if (publicKeys.value.length === 0) {
    await getPublicKeys()
    if (publicKeys.value.length === 0) {
      return false
    }
  }
  // TODO: Support multiple public keys.
  const publicKeyJson = publicKeys.value[0]
  if (!publicKeyJson) {
    console.log('invalid public key:', publicKeys)
    return false
  }
  const alg = publicKeyJson.alg
  const kid = publicKeyJson.kid
  const publicKey = KEYUTIL.getKey(publicKeyJson)
  const isValid = KJUR.jws.JWS.verifyJWT(myToken, publicKey, {
    alg: [alg],
    kid: [kid],
    gracePeriod: 5 * 60, // accept 5 minutes slow or fast
  })
  return isValid
}
